import React, {FC} from 'react';
import { graphql, Script } from 'gatsby';

import {GatsbySeo} from 'gatsby-plugin-next-seo/lib';
import {useIntl} from 'gatsby-plugin-intl';

import {
  AccessoryData,
  AllWpTopBar, ListedAccessoryNode,
  ListedProductNode,
  ProductMajorData,
  ProductPhotos,
} from 'types/accessories.types';
import {getLink} from '@helpers/linking.helper';
import {PageType} from '@constants/pageType.constants';

import {ProductSpecification} from '../../app/product/components/product-specification/ProductSpecification.component';
import {ProductVideo} from '../../app/product/components/product-video/ProductVideo.component';
import {WhyThis} from '@components/why-this/WhyThis.component';
import {AccessoryFeatured} from '@components/accessory-featured/AccessoryFeatured.component';
import FeaturedProducts from '@components/featured-products/FeaturedProducts.component';
import {convertStringToBoolean} from '../../app/product/product.helper';
import ProductInfoGrid from '../../app/product/components/product-info-grid/ProductInfoGrid.component';
import {ProductThumbnailsHelper} from '@helpers/thumbnails.helper';
import {StoreSearch} from '@components/store-search/StoreSearch.component';

interface IAccessoryTemplate {
  data: {
    wpAccessory: ListedAccessoryNode
    wpFeaturedProduct: {
      featuredProducts: {
        products: ListedProductNode[]
      }
    }
    allWpTopBar: AllWpTopBar;
  }
  path: string
}

const AccessoryTemplate: FC<IAccessoryTemplate> = (props) => {
  const {
    data: {
      wpAccessory,
      wpFeaturedProduct,
      allWpTopBar,
    }
  } = props;

  const intl = useIntl();

  const product: AccessoryData = wpAccessory?.productData;

  const canonicalLink = 'https://avionaut.com' + `${getLink(intl.locale, PageType.ACCESSORIES)}` + '/' + `${wpAccessory.slug}` + '/';
  const isTopBarVisible = allWpTopBar.edges[0].node && convertStringToBoolean[allWpTopBar.edges[0].node.topBarData.istopbarvisible];

  const productData: ProductMajorData = {
    carSeatName: product.name,
    carSeatNameBold: product.namebolded,
    properties: product.properties,
    productTags: product.producttags,
    tags: product.tags,
    colors: product.productavailablecolors,
    points: product.bulleteddescription,
    instruction: product.instructionfile,
    sizesPhoto: product.sizestable
  }

  const productPhotos: ProductPhotos = {
    featuredPhotos: product.featuredphotos,
    frontPhotos: product.frontphotos,
    sidePhotos: product.sidephotos,
    featuredPhotosTwo: product.featuredphotostwo,
    backPhotos: product.backphotos,
    additionalPhotosOne: product.additionalphotosone,
    additionalPhotosTwo: product.additionalphotostwo,
    additionalPhotosThree: product.additionalphotosthree,
  };

  const jsonData = {
    '@context': 'https://schema.org/',
    '@graph':
      [
        {
          '@type': 'Product',
          'name': `Avionaut - ${product.namebolded}`,
          'images': ProductThumbnailsHelper[wpAccessory.slug],
          'description': product.metadescription,
          'brand': 'Avionaut',
          'aggregateRating': {
            '@type': 'AggregateRating',
            'ratingValue': 5,
            'reviewCount': 1
          }
        },
        {
          '@type': 'Organization',
          'url': `https://avionaut.com${getLink(intl.locale, PageType.START)}`,
          'logo': 'https://avionaut.com/avionaut.svg'
        },
      ]
  }
  
  return (
    <>
      {product &&
        <>
        <Script type="application/ld+json">{JSON.stringify(jsonData)}</Script>
          <GatsbySeo
            htmlAttributes={{
              language: intl.locale
            }}
            language={intl.locale}
            languageAlternates={[
              {
                hrefLang: 'x-default',
                href: `https://avionaut.com${getLink(intl.locale, PageType.ACCESSORY)}/${wpAccessory.slug}/`,
              },
              {
                hrefLang: 'en',
                href: `https://avionaut.com${getLink('en', PageType.ACCESSORY)}/${wpAccessory.slug}/`,
              },
              {
                hrefLang: 'pl',
                href: `https://avionaut.com${getLink('pl', PageType.ACCESSORY)}/${wpAccessory.slug}/`,
              },
              {
                hrefLang: 'de',
                href: `https://avionaut.com${getLink('de', PageType.ACCESSORY)}/${wpAccessory.slug}/`,
              },
              {
                hrefLang: 'cz',
                href: `https://avionaut.com${getLink('cz', PageType.ACCESSORY)}/${wpAccessory.slug}/`,
              },
              {
                hrefLang: 'hr',
                href: `https://avionaut.com${getLink('hr', PageType.ACCESSORY)}/${wpAccessory.slug}/`,
              },
              {
                hrefLang: 'es',
                href: `https://avionaut.com${getLink('es', PageType.ACCESSORY)}/${wpAccessory.slug}/`,
              },
              {
                hrefLang: 'fr',
                href: `https://avionaut.com${getLink('fr', PageType.ACCESSORY)}/${wpAccessory.slug}/`,
              },
            ]}
            canonical={canonicalLink}
            title={`Avionaut - ${product.namebolded}`}
            description={product.metadescription}
            metaTags={[
              {
                property: 'thumbnail',
                content: ProductThumbnailsHelper[wpAccessory.slug],
              },
              {
                property: 'og:title',
                content: product.metatitle,
              },
              {
                property: 'og:description',
                content: product.metadescription,
              },
              {
                property: 'twitter:title',
                content: product.metatitle,
              },
              {
                property: 'twitter:description',
                content: product.metadescription,
              },
            ]}
          />
        />
          <ProductInfoGrid
            product={productData}
            photos={productPhotos}
            isTopBarVisible={isTopBarVisible}
          isAccessory
          />
          <WhyThis
            header={product.whythisproductheader}
            text={product.whythisproducttext}
            bigImg={product.whythisproductbiggerphotoleft}
            smallImg={product.whythisproductsmallerphotoright}
            isAccessories
          />
          <ProductSpecification
            specification={product.specs}
            header={intl.formatMessage({id: 'product__specificationHeader'})}
          />
          <AccessoryFeatured
            rows={product.featureAccessory}
            header={product.featureofaccessoryheader}
            description={product.featureofaccessorytext}
          />
          <ProductVideo
            productVideos={product.productvideos}
            header={product.videoheader}
            sublineTxt={intl.formatMessage({id: 'product__playVideo'})}
          />
          <FeaturedProducts
            productCart
            header={intl.formatMessage({id: 'featuredAccessories__header'})}
            products={wpFeaturedProduct.featuredProducts.products}
          />
          <StoreSearch
            header={intl.formatMessage({id: 'product__storeSearchHeader'})}
            fieldText={intl.formatMessage({id: 'product__storeSearchPlaceholder'})}
            shopsURL={getLink(intl.locale, PageType.SHOPS)}
            currentLocale={intl.locale}
            isBackgroundVisible
          />
        </>
      }
    </>
  );
};

export const query = graphql`
  query GetSingleAccessory($slug: String, $locale: String) {
    wpAccessory(slug: {eq: $slug}, locale: {locale: {eq: $locale}}) {
      locale {
        locale
      }
      title
      slug
      productData {
        specs {
          specsname
          value
        }
        name
        namebolded
        whythisproductheader
        whythisproducttext
        featureofaccessoryheader
        featureofaccessorytext
        metatitle
        metadescription
        instructionfile {
          link
          title
        }
        featureAccessory {
          header
          text
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        bulleteddescription {
          value
        }
        tags
        producttags {
          tagname
        }
        properties
        frontphotos {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        featuredphotos {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        sidephotos {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        backphotos {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        featuredphotostwo {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        additionalphotosone {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        additionalphotostwo {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        additionalphotosthree {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        whythisproductbiggerphotoleft {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100)
            }
          }
        }
        whythisproductsmallerphotoright {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100)
            }
          }
        }
        videoheader
        videocover {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100)
            }
          }
        }
        videocode
        productvideos {
          productvideocode
          productvideothumbnail {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        sizestable {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100)
            }
          }
        }
        productavailablecolors {
          ... on WpColor {
            id
            colorProperties {
              colorhex
              colorname
              colorparameter
            }
          }
        }
      }
    }
    wpFeaturedProduct(locale: {locale: {eq: $locale}}) {
      locale {
        locale
      }
      featuredProducts {
        products {
          ... on WpProduct {
            productMetaData {
              productname
              thumbnail {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
              featuredproductsmeta {
                categoryage
                categoryweight
              }
            }
            productData {
              productavailablecolors {
                ... on WpColor {
                  id
                  colorProperties {
                    colorhex
                    colorname
                    colorparameter
                  }
                }
              }
              tags
            }
            slug
          }
        }
      }
    }
    allWpTopBar(filter: {locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          id
          locale {
            id
            locale
          }
          topBarData {
            buttoncta
            buttontext
            istopbarvisible
            promotext
            promotextmobile
          }
        }
      }
    }
  }

`;

export default AccessoryTemplate;
