import React, { FC } from 'react';
import sanitizeHtml from 'sanitize-html';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { featuredAccessory } from '../../types/accessories.types';

import { Container } from '../container/Container.component';
import { Header } from '../header/Header.component';

import s from './AccessoryFeatured.module.scss';


interface IAccessoryFeatured {
  rows: featuredAccessory[];
  header: string;
  description: string
}

export const AccessoryFeatured: FC<IAccessoryFeatured> = ({ rows, header, description }) => {

  function renderRows() {
    return (
      rows.map((row, index) => {
        return (
          <li className={s.accessoryFeatured__row} key={index}>
            <GatsbyImage
              className={s.accessoryFeatured__image}
              image={getImage(row.photo.localFile)!}
              alt={row.photo.altText}
            />
            <div className={s.accessoryFeatured__description}>
              <h3 className={s.accessoryFeatured__rowHeader}>{row.header}</h3>
              <p className={s.accessoryFeatured__rowText}>{row.text}</p>
            </div>
          </li>
        );
      })
    );
  }

  return (
    <Container>
      <div className={s.accessoryFeatured}>
      <Header>{header}</Header>
        <div className={s.accessoryFeatured__text}>
          <p className={`${s.accessoryFeatured__textStyle} html-styled-link`} dangerouslySetInnerHTML={{
            __html: sanitizeHtml(description, {
              allowedTags: ['strong', 'a'],
              allowedAttributes: {
                'a': ['href'],
              },
            }),
          }}>
          </p>
        </div>
        {renderRows()}
      </div>

    </Container>
  );
};
